export const BASE_DOMAIN = process.env.NEXT_PUBLIC_BASE_DOMAIN
export const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL
export const GOOGLE_ANALYTICS = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS === "true"
export const GOOGLE_ANALYTICS_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID
export const ENVIRONMENT = process.env.NEXT_PUBLIC_ENVIRONMENT
export const POSTHOG_ID = process.env.NEXT_PUBLIC_POSTHOG_ID
export const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY
export const BLOB_READ_WRITE_TOKEN = process.env.BLOB_READ_WRITE_TOKEN

export const YEAR = new Date().getFullYear()
