export enum InstanceStatus {
    CREATING = "creating",
    AGENT_RUNNING = "agent_running",
    ROLLING_BACK = "rolling_back",
    RESUMING = "resuming",
    READY = "ready",
    SAP_STARTING = "sap_starting",
    SAP_RUNNING = "sap_running",
    APP_RUNNING = "app_running",
    PAUSING = "pausing",
    PAUSED = "paused",
    INSTANCE_BACKUP_CREATING = "instance_backup_creating",
    SAP_STOPPING = "sap_stopping",
    SAP_RESTARTING = "sap_restarting",
    SAP_STOPPED = "sap_stopped",
    APP_STOPPED = "app_stopped",
    SAP_FAILED = "sap_failed",
    APP_FAILED = "app_failed",
    SAP_STOP_FAILED = "sap_stop_failed",
    APP_STOP_FAILED = "app_stop_failed",
    CREATING_FAILED = "creating_failed",
    PAUSING_FAILED = "pausing_failed",
    RESUMING_FAILED = "resuming_failed",
    INSTANCE_BACKUP_FAILED = "instance_backup_failed",
    DELETING_FAILED = "deleting_failed",
    DELETING = "deleting",
    DELETED = "deleted",
}

export enum InstanceType {
    SAP = "sap",
    INSTALL = "install",
    CLOUD_CONNECTOR = "cloud_connector",
    RUNNER = "runner",
}

export enum AutomationRunStatus {
    RUNNING = "running",
    SUCCESS = "success",
    FAILED = "failed",
    CANCELED = "canceled",
}

export enum AutomationStepStatus {
    PENDING = "pending",
    RUNNING = "running",
    SUCCESS = "success",
    FAILED = "failed",
    CANCELED = "canceled",
}
