import { ReactNode, isValidElement } from "react"
import { create } from "zustand"

import { randomString } from "@lib/helpers"

const TOAST_DEFAULT_EXPIRATION = 4000

export enum ToastType {
    SUCCESS,
    INFO,
    WARNING,
    ERROR,
}

export type NewToast = {
    message: string | ReactNode
    type?: ToastType
    expiration?: number
}

export type Toast = {
    id?: string
    message: string | ReactNode
    type: ToastType
    expiration?: number
}

type ToastsStore = {
    toasts: Toast[]

    addToast: (newToast: Toast) => void
    removeToast: (toast: Toast) => void
}

export const useToastsStore = create<ToastsStore>((set, get) => ({
    toasts: [],

    addToast: (newToast: Toast) => {
        const currentToasts = get().toasts
        const removeToast = get().removeToast

        const currentType = newToast.type ?? ToastType.INFO
        const currentMessageChildren = isValidElement(newToast?.message)
            ? (newToast?.message as React.ReactElement).props?.children
            : newToast?.message
        const transNewToast: Toast = {
            id: randomString(8),
            message: newToast.message,
            type: currentType,
            expiration: newToast.expiration ?? TOAST_DEFAULT_EXPIRATION,
        }

        // Remove the older duplicates
        for (const toast of currentToasts) {
            if (toast.message && isValidElement(toast.message)) {
                const toastMessageChildren = (toast.message as React.ReactElement).props
                    .children

                if (
                    JSON.stringify(toastMessageChildren) ===
                    JSON.stringify(currentMessageChildren)
                ) {
                    removeToast(toast)
                }
            }
        }

        // add
        set({ toasts: [...currentToasts, transNewToast] })

        // remove later
        setTimeout(() => {
            removeToast(transNewToast)
        }, transNewToast.expiration)
    },

    removeToast: (toast: Toast) => {
        const toasts = get().toasts
        const filterdToasts = toasts.filter(({ id }) => {
            return id !== toast.id
        })

        set({ toasts: filterdToasts })
    },
}))
