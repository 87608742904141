import clsx from "clsx"
import moment from "moment"

import { InstanceStatus, InstanceType } from "@lib/api/enums"
import {
    Instance,
    InstanceBackup,
    InstanceSchedule,
    Landscape,
    Organization,
    SapSystem,
    User,
} from "@lib/api/models"
import { BASE_DOMAIN } from "@lib/constants"
import dayjs from "@lib/dayjs"
import permissions from "@lib/permissions"

export const delay = async (n: number) => {
    return await new Promise((resolve) => setTimeout(resolve, n))
}

export const flatten = (lists: unknown[][]): unknown[] => {
    const flat: unknown[] = []
    lists.forEach((list) => {
        list.forEach((item) => {
            flat.push(item)
        })
    })
    return flat
}

export const shortDemoVersionName = (instance: Instance): string =>
    `${instance.backup?.version.package.description} (${instance.zone
        .split("-")[0]
        .toUpperCase()})`

export const unslugify = (s: string): string => s.replace(/[_-]/g, " ")

export const capitalize = (s: string): string =>
    (s.charAt(0).toUpperCase() + s.slice(1)).replace("Sap", "SAP")

export const getLongDateStr = (date: string | Date): string => {
    return dayjs(date).format("MMMM D, YYYY h:mm A")
}

// Convert a date to a string YYYY-MM
export function yyyymmString(date: Date) {
    return date.toISOString().slice(0, 7)
}

// Convert a date to a string MMM DD, YYYY
export const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    })
}

export function formatDuration(from_date: Date, to_date: Date): string {
    // Duration in seconds
    let step_duration = dayjs(to_date).diff(dayjs(from_date), "second")
    let step_duration_str = `${step_duration.toFixed(0)}s`

    // Divide duration from seconds to minutes if long enough
    if (step_duration > 60) {
        step_duration = step_duration / 60
        step_duration_str = `${step_duration.toFixed(0)}m`
    }

    // Divide duration from minutes to hours if long enough
    if (step_duration > 60) {
        step_duration = step_duration / 60
        step_duration_str = `${step_duration.toFixed(1)}h`
    }

    return step_duration_str
}

export function randomString(l = 16) {
    return Math.random().toString(36).substr(2, l)
}

export const validateEmail = (email: string): boolean => {
    const emailReg = new RegExp("^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")
    return emailReg.test(email)
}

export const validatePhone = (phone: string): boolean => {
    const phoneReg = new RegExp(
        // eslint-disable-next-line no-useless-escape
        "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$",
    )
    return phoneReg.test(phone)
}

export const validatePassword = (password: string): boolean => {
    const passwordReg = new RegExp(".{8,}")
    return passwordReg.test(password)
}

export const getImageName = (description: string | null): string => {
    if (!description) {
        return "OTHER"
    }

    const lowerDesc = description.toLowerCase()

    if (lowerDesc.includes("erp") || lowerDesc.includes("ecc")) {
        return "ECC"
    } else if (lowerDesc.includes("s/4") || lowerDesc.includes("hana")) {
        return "HANA"
    } else {
        return "OTHER"
    }
}

export const sortBackupByDate = (backups: InstanceBackup[]): InstanceBackup[] => {
    const sortByDate = (s1: InstanceBackup, s2: InstanceBackup) => {
        if (new Date(s1.created_at) > new Date(s2.created_at)) {
            return -1
        } else if (new Date(s1.created_at) < new Date(s2.created_at)) {
            return 1
        } else {
            return 0
        }
    }

    return backups.sort(sortByDate)
}

export const groupBackupsByRegions = (
    backups: InstanceBackup[],
): { group: string; backups: InstanceBackup[] }[] => {
    const groups: { group: string; backups: InstanceBackup[] }[] = []

    for (const backup of backups) {
        const updatedBackup = {
            ...backup,
            version: { ...backup.version, zone: backup.source_instance.zone },
        }
        const groupName = `${updatedBackup.version.package.description} (${updatedBackup.source_instance.zone})`

        if (groups.some((obj: { group: string }) => obj.group === groupName)) {
            const i = groups.findIndex(
                (obj: { group: string }) => obj.group === groupName,
            )
            groups[i].backups.push(updatedBackup)
            sortBackupByDate(groups[i].backups)
        } else {
            groups.push({
                group: groupName,
                backups: [updatedBackup],
            })
        }
    }

    return groups
}

export const parseGroupName = (group: string) => {
    const regex = /(.*)\(([a-z0-9-]+)\)$/i
    const match = regex.exec(group)
    if (!match) throw new Error(`Invalid group name: ${group}`)
    return {
        sapVersion: match[1].trim(),
        region: match[2].trim(),
    }
}

export const aggregateBackupsByName = (backups: InstanceBackup[]): SapSystem[] => {
    const aggregatedBackups = [] as SapSystem[]
    // Sort by storage location, z-a
    const sortedByRegion = (s1: InstanceBackup, s2: InstanceBackup) => {
        const regionA = s1.source_instance.zone.toUpperCase()
        const regionB = s2.source_instance.zone.toUpperCase()

        if (regionA > regionB) {
            return -1
        } else if (regionA < regionB) {
            return 1
        } else {
            return 0
        }
    }
    const arrayForSort = [...backups]
    arrayForSort.sort(sortedByRegion)

    arrayForSort.forEach((backup) => {
        const existingBackup = aggregatedBackups.find((obj) => {
            return obj.name === backup.name
        })
        if (existingBackup) {
            // Add additional regions
            const region = backup.source_instance.zone.split("-")[0].toUpperCase()
            existingBackup.available_regions =
                existingBackup.available_regions + ", " + region
        } else {
            const region = backup.source_instance.zone.split("-")[0].toUpperCase()
            const aggregatedBackup = {
                name: backup.name,
                available_regions: region,
                status: backup.status,
            } as SapSystem
            aggregatedBackups.push(aggregatedBackup)
        }
    })

    return aggregatedBackups
}

export const sentenceCase = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export const getNameInitial = (name: string): string => {
    const nameParts = name.split(" ")
    const initials = nameParts.map((part) => part.charAt(0).toUpperCase())

    return initials[0]
}

// Instances
// -----------------------------------------------------------------------------

export const isTrialAcct = (user: User | null): boolean => {
    return user !== null && user.organization_user[0].organization.tier === "trial"
}

export const isInstanceReady = (instance: Instance): boolean => {
    const statuses: string[] = [
        InstanceStatus.SAP_RUNNING,
        InstanceStatus.APP_RUNNING,
        InstanceStatus.READY,
    ]
    return statuses.includes(instance.status)
}

export const isInstanceFailed = (instance: Instance): boolean => {
    const statuses: string[] = [
        InstanceStatus.SAP_FAILED,
        InstanceStatus.APP_FAILED,
        // InstanceStatus.SAP_STOP_FAILED,
        // InstanceStatus.APP_STOP_FAILED,
        // InstanceStatus.CREATING_FAILED,
        // InstanceStatus.PAUSING_FAILED,
        InstanceStatus.RESUMING_FAILED,
        // InstanceStatus.INSTANCE_BACKUP_FAILED,
        // InstanceStatus.DELETING_FAILED,
    ]
    return statuses.includes(instance.status)
}

export const hasViewPermission = (user: User | null): boolean => {
    return permissions.hasPermission("view-instances", user)
}

export const hasDeletePermission = (user: User | null): boolean => {
    return permissions.hasPermission("delete-instances", user)
}

export const canViewInstance = (user: User | null): boolean => {
    return hasViewPermission(user)
}

export const canCreateBackup = (instance: Instance, user: User | null): boolean => {
    return isInstanceReady(instance) && hasDeletePermission(user)
}

export const canRollbackBackup = (instance: Instance, hasBackups: boolean): boolean => {
    return isInstanceReady(instance) && hasBackups && instance.type == InstanceType.SAP
}

export const canRestartSap = (instance: Instance, user: User | null): boolean => {
    return (
        (isInstanceReady(instance) || isInstanceFailed(instance)) &&
        hasDeletePermission(user) &&
        instance.type == InstanceType.SAP
    )
}

export const canDeleteInstance = (instance: Instance, user: User | null): boolean => {
    // Check instance
    const isInstanceApproved =
        ![
            InstanceStatus.CREATING,
            InstanceStatus.DELETING,
            InstanceStatus.DELETED,
            InstanceStatus.INSTANCE_BACKUP_CREATING,
        ].includes(instance.status) && instance.type != InstanceType.RUNNER

    // Check user permission
    const isUserApproved = hasDeletePermission(user)

    // Return result
    return isInstanceApproved && isUserApproved
}

export const canPauseInstance = (
    instance: Instance,
    organization: Organization,
): boolean => {
    return (
        organization.feature_flags?.pause_resume &&
        [InstanceStatus.SAP_RUNNING, InstanceStatus.APP_RUNNING].includes(
            instance.status,
        )
    )
}

export const canResumeInstance = (
    instance: Instance,
    organization: Organization,
): boolean => {
    return (
        organization.feature_flags?.pause_resume &&
        instance.status === InstanceStatus.PAUSED
    )
}

export const canSchedule = (
    instance: Instance,
    organization: Organization,
): boolean => {
    return (
        organization.feature_flags?.schedule &&
        ![InstanceStatus.DELETING, InstanceStatus.DELETED].includes(instance.status)
    )
}
export const canHideApplicationServer = (organization: Organization): boolean => {
    return organization.slug.toLowerCase().includes("nuve")
}

export const showConnect = (instance: Instance): boolean => {
    return (
        isInstanceReady(instance) &&
        (instance.type == InstanceType.SAP ||
            instance.type == InstanceType.CLOUD_CONNECTOR)
    )
}

export const getBackupsByPackage = (
    instance: Instance,
    backups: InstanceBackup[],
): InstanceBackup[] => {
    const filteredBackups = backups.filter((backup: InstanceBackup) => {
        return backup.version.package.id === instance.backup?.version.package.id
    })

    return [...filteredBackups]
}

export const showExtend = (instance: Instance, isIndividual: boolean): boolean => {
    if (instance.status === "deleted") return false

    const isDeleteInHalfHours =
        instance?.scheduled_shutdown !== undefined &&
        Math.round(
            (new Date(instance.scheduled_shutdown).getTime() - new Date().getTime()) /
                1000 /
                60,
        ) <= 30
    return isIndividual && isDeleteInHalfHours
}

export const getSapVersionsFromBackups = (backups: InstanceBackup[] | null) => {
    if (backups) {
        let versions = backups.map((backup) => {
            return backup.version
        })

        versions = versions.filter((version, index) => {
            return (
                index ===
                versions.findIndex((curVersion) => version.id === curVersion.id)
            )
        })

        versions = versions.map((version) => {
            const sapPackage = version.package
            const licenseAgreement = sapPackage.license_agreement
                ? {
                      ...sapPackage.license_agreement,
                  }
                : null
            const zone = sapPackage.instance_requirements.zone
            return {
                ...version,
                zone: zone,
                package: {
                    ...sapPackage,
                    license_agreement: licenseAgreement,
                },
            }
        })

        return versions
    } else {
        return []
    }
}

export const getCloudConnectorConnectClick = (
    instance: Instance,
    organization: Organization,
) => {
    const cloudConnectorConnectUrl = `https://cloud-connector-${instance.name}.${organization.slug}.${BASE_DOMAIN}`
    return () => window.open(cloudConnectorConnectUrl, "_blank")
}

// Schedule
// -----------------------------------------------------------------------------

export const getCurrentDateTime = (input: string) => {
    return moment(moment().format().substring(0, 10) + input.substring(10))
}

export const getNumFromDay = (day: string) => {
    switch (day) {
        case "MON":
            return 1
        case "TUE":
            return 2
        case "WED":
            return 3
        case "THU":
            return 4
        case "FRI":
            return 5
        case "SAT":
            return 6
        case "SUN":
            return 7
        default:
            return 0
    }
}

export const clearSeconds = (date: Date) => {
    const parsedDate = new Date(new Date(date.setSeconds(0)).setMilliseconds(0))

    return parsedDate
}

export const getThreeHoursLater = () => {
    const date = clearSeconds(new Date(new Date().getTime() + 60000 * 180))
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
    }
    const formattedDate = new Intl.DateTimeFormat(
        undefined,
        options as Intl.DateTimeFormatOptions,
    )
        .format(date)
        .replace(",", "")

    return formattedDate
}

export const getConnectionFile = (
    instance: Instance,
    client: string,
    instanceDomain: string,
) => {
    const config = instance.backup?.version.package.config
    const fileContent = `[Register]\n[System]\nGuiParm=${instanceDomain} ${config?.sap_system_no}\nName=${config?.sap_system_id}\nClient=${client}\n[User]\n[Function]\n[Configuration]\n[Options]`

    return fileContent
}

export const validateSchedulePauseAndResume = (
    showPauseAndResume: boolean,
    schedule: InstanceSchedule | undefined,
    setSchedulePauseAndResumeErrors: React.Dispatch<
        React.SetStateAction<{
            resumeTime: string | null
            pauseTime: string | null
            days: string | null
        }>
    >,
) => {
    if (!showPauseAndResume) {
        return false
    } else {
        // resume
        let resumeError: string | null = null

        if (!schedule || !schedule.start) {
            resumeError = 'Please enter the time using the format "9:00 AM".'
        } else if (schedule.stop && schedule.start === schedule.stop) {
            resumeError = "Pause and Resume times must be different."
        }

        setSchedulePauseAndResumeErrors((prevErrors) => ({
            ...prevErrors,
            ["resumeTime"]: resumeError,
        }))

        // pause
        let pauseError: string | null = null

        if (!schedule || !schedule.stop) {
            pauseError = 'Please enter the time using the format "5:00 PM".'
        } else if (schedule.start && schedule.start === schedule.stop) {
            pauseError = "Pause and Resume times must be different."
        }

        setSchedulePauseAndResumeErrors((prevErrors) => ({
            ...prevErrors,
            ["pauseTime"]: pauseError,
        }))

        // days
        let daysError: string | null = null

        if (!schedule || !schedule.days || schedule.days.length === 0) {
            daysError = "Please pick at least one day."
        }

        setSchedulePauseAndResumeErrors((prevErrors) => ({
            ...prevErrors,
            ["days"]: daysError,
        }))

        return resumeError || pauseError || daysError
    }
}

export const validateSchedulePause = (
    showSchedulePause: boolean,
    scheduledPause: Date | undefined,
    showScheduleDelete: boolean,
    scheduledDelete: Date | undefined,
    setSchedulePauseError: React.Dispatch<React.SetStateAction<string | null>>,
) => {
    if (!showSchedulePause) {
        return false
    } else {
        // resume
        let error: string | null = null

        if (!scheduledPause) {
            error = 'Please enter the time using the format "04/15/2050 5:00 PM".'
        } else if (scheduledPause <= new Date()) {
            error = "Please set a time in the future."
        } else if (
            showScheduleDelete &&
            scheduledDelete &&
            scheduledDelete > new Date() &&
            scheduledPause >= clearSeconds(scheduledDelete)
        ) {
            error = "Please set a time before the scheduled delete time."
        }

        setSchedulePauseError(error)

        return error
    }
}

export const validateScheduleDelete = (
    showScheduleDelete: boolean,
    scheduledDelete: Date | undefined,
    showSchedulePause: boolean,
    scheduledPause: Date | undefined,
    setScheduleDeleteError: React.Dispatch<React.SetStateAction<string | null>>,
) => {
    if (!showScheduleDelete) {
        return false
    } else {
        // resume
        let error: string | null = null

        if (!scheduledDelete) {
            error = 'Please enter the time using the format "04/15/2050 5:00 PM".'
        } else if (scheduledDelete <= new Date()) {
            error = "Please set a time in the future."
        } else if (
            showSchedulePause &&
            scheduledPause &&
            scheduledPause > new Date() &&
            scheduledDelete <= clearSeconds(scheduledPause)
        ) {
            error = "Please set a time after the scheduled pause time."
        }

        setScheduleDeleteError(error)

        return error
    }
}

// Landscapes
// -----------------------------------------------------------------------------

export const getLandscapeStatusClass = (status: string) => {
    return clsx("rounded px-2 py-1 text-sm font-medium", {
        "bg-yellow-100 text-yellow-600": status === "starting" || status === "stopped",
        "bg-green-100 text-green-600": status === "running",
        "bg-red-100 text-red-600": status === "failed",
    })
}

export const getEnvsFromLandscapes = (
    landscapes: Landscape[] | undefined,
    environmentSlug: string,
) => {
    if (!Array.isArray(landscapes)) {
        return []
    }

    for (const landscape of landscapes) {
        if (landscape.environments.some((env) => env.slug === environmentSlug)) {
            return landscape.environments
        }
    }

    return []
}

// Compare Objects
const sortObject = (obj: any): any => {
    if (obj === null || typeof obj !== "object") {
        return obj
    }

    if (Array.isArray(obj)) {
        return obj.map(sortObject)
    }

    const sortedKeys = Object.keys(obj).sort()
    const result: any = {}

    sortedKeys.forEach((key) => {
        result[key] = sortObject(obj[key])
    })

    return result
}

export const compareObjects = (obj1: any, obj2: any): boolean => {
    const sortedObj1 = sortObject(obj1)
    const sortedObj2 = sortObject(obj2)

    return JSON.stringify(sortedObj1) === JSON.stringify(sortedObj2)
}
